// in this file we utilize apollo reactive variable concept
// to store data outside the apollo client but have possibility to read it inside
// the apollo client and also possibility to update the value from the app
import { makeVar } from '@apollo/client';
import { CustomerFragment } from '@generated';
import { UUID } from 'src/types/basic';

// this map holds customer information and utilizes user uuids as key property
export const customersMapReactiveVar = makeVar<Record<UUID, CustomerFragment>>(
  {}
);
