import { ApolloError, NetworkStatus } from '@apollo/client';
import {
  GetCustomersByUserQuery,
  useGetCustomersByUserQuery,
} from '@generated';
import { useEffect } from 'react';
import { customersMapReactiveVar } from 'src/services/apiClients/reactiveVars';
import { UUID } from 'src/types/basic';

interface UseGetCustomerByUser {
  data?: GetCustomersByUserQuery;
  error?: ApolloError;
  isLoading: boolean;
  isReady: boolean;
}
export const useGetCustomerByUser = (
  userUuid: UUID | null | undefined
): UseGetCustomerByUser => {
  const {
    data,
    stopPolling,
    error,
    loading: isLoading,
    networkStatus,
  } = useGetCustomersByUserQuery({
    variables: { userUuid },
    fetchPolicy: 'no-cache',
    skip: !userUuid,
    pollInterval: 2000,
  });

  const isReady = networkStatus === NetworkStatus.ready;

  useEffect(() => {
    if ((data?.customers?.nodes?.length ?? 0) > 0 || !!error) {
      stopPolling();
    }
  }, [data?.customers?.nodes?.length, stopPolling, error]);

  useEffect(() => {
    const currentCustomers = customersMapReactiveVar();
    const customer = data?.customers?.nodes?.[0];
    if (!customer || !userUuid) {
      return;
    }
    customersMapReactiveVar({
      ...currentCustomers,
      [userUuid]: customer,
    });
  }, [data, userUuid]);
  return { data, error, isLoading, isReady };
};
